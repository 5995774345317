<!-- eslint-disable vue/no-v-html -->
<template>
	<custom-modal
		:show.sync="showStoreModal"
		title=""
		:modal-size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom-3'"
		:show-edge="false"
		ref-name="requestSponsorModal"
		@cancel="() => {}">
		<template #header>
			<div class="d-flex justify-content-center align-items-center my-2 mx-3">
				<div>
					<img
						:src="`${S3_PATH}/assets/themes/${theme}/symbol_white.svg`"
						alt=""
						width="100px"
						class="mr-3">
				</div>
				<div>
					<h2
						v-if="redirectType === 'customer'"
						class="text-white m-0">
						{{ translate('become_a_customer') }}
					</h2>
					<div
						v-else
						:style="language === 'es' ? 'max-width: 260px' : 'max-width: 220px'">
						<h2
							class="text-white m-0">
							{{ translate('join_us_now') }}
						</h2>
						<p class="text-white">
							{{ translate('join_us_now_description') }}
						</p>
					</div>
				</div>
			</div>
		</template>
		<b-row
			class="px-4"
			style="margin-top: -20px; margin-bottom: 30px">
			<b-col
				:cols="['xs', 'sm'].includes(windowWidth) ? '12' : '5'"
				:class="{ 'separator': !['xs', 'sm'].includes(windowWidth) }">
				<h2 v-html="translate('have_an_sponsor')" />
				<p>{{ translate('please_add_sponsor_1') }}</p>
				<div class="form-group">
					<label class="text-muted font-weight-bold">{{ translate('sponsor_username') }}</label>
					<input-text
						:setter-value="form.sponsor"
						:required="true"
						:placeholder="translate('username_example')"
						type="text"
						@dataChanged="form.sponsor = $event" />
					<div
						class="text-danger"
						v-html="form.error" />
				</div>
				<b-button
					variant="primary"
					@click="showStore()">
					{{ translate('submit') }}
				</b-button>
			</b-col>
			<b-col
				:cols="['xs', 'sm'].includes(windowWidth) ? '12' : '7'"
				:class="{ 'mt-3': ['xs', 'sm'].includes(windowWidth) }">
				<h2
					class=""
					v-html="translate('dont_know_sponsor')" />
				<p v-html="translate('please_add_sponsor_2', { supportLink: companyEmail, support: companyEmail })" />
			</b-col>
		</b-row>
		<template #footer>
			<div />
		</template>
	</custom-modal>
</template>
<script>
import CustomModal from '@/components/CustomModal/index.vue';
import InputText from '@/components/InputText/index.vue';
import WindowSizes from '@/mixins/WindowSizes';
import { URIS } from '@/settings/LiveChat';
import { Store } from '@/translations';
import ReplicatedSite from '@/util/ReplicatedSite';
import { S3_PATH } from '@/settings/Images';

export default {
	name: 'RequestSponsorModal',
	components: { InputText, CustomModal },
	messages: [Store],
	mixins: [WindowSizes],
	props: {
		redirectType: {
			type: String,
			default: 'customer',
		},
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			replicated: new ReplicatedSite(),
			form: {
				sponsor: '',
				error: '',
			},
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
			openLiveChat: URIS.MAXIMIZE_CHAT,
			S3_PATH,
			theme: process.env.VUE_APP_THEME,
		};
	},
	computed: {
		showStoreModal: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		'form.sponsor': {
			handler() {
				this.form.error = '';
			},
		},
		showStoreModal(show) {
			if (show) {
				this.form.sponsor = '';
			}
		},
	},
	methods: {
		showStore() {
			this.replicated.getReplicatedSiteNav(this.form.sponsor)
				.then((response) => {
					const { user, replicated_site: replicatedId } = response.response;
					if ((this.redirectType === 'customer' && user.can_sponsor_checkout) || (this.redirectType === 'member' && user.can_register)) {
						this.$router.replace({
							name: 'ReplicatedSiteBar',
							params: { replicatedId },
							query: { option: this.redirectType === 'customer' ? 'our-products' : 'signup' },
						});
					} else {
						this.form.error = this.translate('cant_sponsor_orders_link', { link: this.openLiveChat });
					}
				})
				.catch(() => {
					this.form.error = this.translate('invalid_user');
				});
		},
	},
};
</script>
<style scoped>
h2 {
	font-family: MontserratExtraBold, Montserrat, sans-serif !important;
}

p, button, label {
	font-family: Montserrat, sans-serif !important;
}

.separator {
	border-right: 1px solid #ccc;
	height: 100%;
}
.form-group /deep/ input {
	font-family: Montserrat, sans-serif !important;
	border: none;
	border-bottom: 1px solid #bfbfbf;
	border-radius: 0;

	/* Style when focused */
	&:focus {
		border-bottom-color: orange;
		outline: none;
		box-shadow: 0 0 0 0;
	}
}
</style>
